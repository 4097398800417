import { Routes, RouterModule } from '@angular/router';
//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: './changelog/changelog.module#ChangeLogModule',
  },
  {
    path: 'full-layout',
    loadChildren: './pages/full-pages/full-pages.module#FullPagesModule',
  },
  {
    path: 'permission',
    loadChildren: './pages/permission/permission.module#PermissionModule'
  },
  {
    path: 'users',
    loadChildren: './pages/users/users.module#UsersModule'
  },
  {
    path: 'companies',
    loadChildren: './pages/companies/companies.module#CompaniesModule'
  },
  {
    path: 'representatives',
    loadChildren: './pages/representatives/representatives.module#RepresentativesModule'
  },
  {
    path: 'search',
    loadChildren: './pages/search/searchs/searchs.module#SearchsModule'
  },
  {
    path: 'search/category',
    loadChildren: './pages/search/category/category.module#CategoryModule'
  },
  {
    path: 'search/group',
    loadChildren: './pages/search/group/group.module#GroupModule'
  },
  {
    path: 'search/questions',
    loadChildren: './pages/search/questions/questions.module#QuestionsModule'
  },
    {
    path: 'meeting',
    loadChildren: './pages/meeting/meetings/meetings.module#MeetingsModule'
  },
  {
    path: 'meeting/ata',
    loadChildren: './pages/meeting/ata/ata.module#AtaModule'
  },
  {
    path: 'meeting/presence',
    loadChildren: './pages/meeting/presence/presence.module#PresenceModule'
  },
  {
    path: 'meeting/scheduel',
    loadChildren: './pages/meeting/scheduel/scheduel.module#ScheduelModule'
  },
  {
    path: 'search/answer',
    loadChildren: './pages/search/answer/answer.module#AnswerModule'
  },
  {
    path: 'content',
    loadChildren: './pages/content/content.module#ContentModule'
  },
  {
    path: 'mail',
    loadChildren: './pages/mails/mails.module#MailsModule'
  }





];
