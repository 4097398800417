import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    {
        path: '', role:'adm', title: 'Administração', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            {
                path: '', role:'adm', title: 'Usuários', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/users', 
                        role:'adm',
                        title: 'Lista', 
                        icon: 'ft-user', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    },
                   { path: '/permission', 
                        role:'adm',
                        title: 'Permissões', 
                        icon: 'ft-lock', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    }
                ]
            },
      
            {
                path: '', role:'adm', title: 'Empresas', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                    { path: '/companies', 
                        role:'adm',
                        title: 'Lista', 
                        icon: 'ft-globe', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    },
                    { path: '/representatives', 
                        role:'adm',
                        title: 'Representantes', 
                        icon: 'ft-users', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    }
                ]
            },
            {
                path: '', role:'adm', title: 'Pesquisas', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [

                    { path: '/search/category', 
                        role:'adm',
                        title: 'Categoria', 
                        icon: 'ft-hash', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    },
                    { path: '/search/group', 
                        role:'adm',
                        title: 'Grupo', 
                        icon: 'ft-users', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    },
                    { path: '/search', 
                        role:'adm',
                        title: 'Lista', 
                        icon: 'ft-file-text', 
                        class: '', 
                        badge: '', 
                        badgeClass: '', 
                        isExternalLink: false, 
                        submenu: [] 
                    }
                ]
            },
            {
                 path: '/meeting', 
                 role:'adm', 
                 title: 'Reuniões', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            },
            {
                path: '/content', role:'adm', title: 'Conteúdo', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [
                ]
            },

        ]
    },
    {
        path: '/search/answer', 
        role:'adm', 
        title: 'Pesquisa', 
        icon: 'ft-filter', 
        class: '', 
        badge: '', 
        badgeClass: '', 
        isExternalLink: false,  
        submenu: [] 
    },
        {
        path: '', role:'adm', title: 'Empresas', icon: 'ft-briefcase', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            {
                 path: '/companies/list', 
                 role:'adm', 
                 title: 'Ativas', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            },
           {
                 path: '/companies/suspension', 
                 role:'adm', 
                 title: 'Suspensas', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            },
            {
                 path: '/companies/licensed', 
                 role:'adm', 
                 title: 'Licenciadas', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            },
            {
                 path: '/companies/excluded', 
                 role:'adm', 
                 title: 'Exclúida', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            },
            {
                 path: '/companies/segment', 
                 role:'adm', 
                 title: 'Segmento', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            }

        ]
    },
        {
        path: '/meeting/list', 
        role:'adm', 
        title: 'Reuniões', 
        icon: 'ft-calendar', 
        class: '', 
        badge: '', 
        badgeClass: '', 
        isExternalLink: false,  
        submenu: [] 
    },

            {
        path: '', role:'adm', title: 'Representantes', icon: 'ft-users', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            {
                 path: '/representatives/list', 
                 role:'adm', 
                 title: 'Todos', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            },
           {
                 path: '/representatives/list/companies', 
                 role:'adm', 
                 title: 'Por empresas', 
                 icon: '', 
                 class: '', 
                 badge: '', 
                 badgeClass: '',
                 isExternalLink: false,
                 submenu: [] 

            }


        ]
    },

    {
        path: '/content/list', 
        role:'adm', 
        title: 'Conteúdo', 
        icon: 'ft-file-text', 
        class: '', 
        badge: '', 
        badgeClass: '', 
        isExternalLink: false,  
        submenu: [] 
    },
    {
        path: '/mail', 
        role:'adm', 
        title: 'Comunicado', 
        icon: 'ft-mail', 
        class: '', 
        badge: '', 
        badgeClass: '', 
        isExternalLink: false,  
        submenu: [] 
    },

];


